import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  useTranslation,
  WidgetProps,
  useBi,
  TFunction,
} from '@wix/yoshi-flow-editor';
import useObservable from '../Shared/Hooks/useObservable.Hook';
import {
  setAppSettings,
  setSettingsDataAPI,
  getSettingsData,
  setSettingsData,
} from './Services/AppSettings.service';
import {
  setRequestLogin,
  setUser,
  setNavigateToPricingPlans,
  setNavigateToLibrary,
} from './Services/User.service';
import FolderTable from './Components/FolderTable/FolderTable';
import { st, classes } from './Widget.st.css';
import {
  authorizeActions,
  getLibraryDataById,
  setSelectedFolder,
  setSelectedFolderId,
} from './Services/SelectedFolder.service';
import { setRootFolder } from './Services/RootFolder.service';
import { searchObservable$ } from './Observables/Search.observable';
import { setBiEventsParams } from './Services/BiEvents.service';
import biService from './BiEvents/BiService';
import { BI_FILE_SHARE_WIDGET_APP_LOADED } from './BiEvents/Constants/BiConstants';
import { getBreadCrumbs, setBreadCrumbs } from './Services/BreadCrumbs.service';
import { ControllerProps } from './ControllerProps';
import { TextAlignment } from '@wix/ambassador-file-sharing-v1-settings/types';
import { EDITORVIEW } from '../Constants/AppSettings.Constant';
import { AppAlignment } from '../Constants/Display.Constants';

import { Status } from '../Constants/Permissions';
import { setOpenedModal } from './Services/Modals.service';
import { MODAL_TYPES } from '../Constants/Modals.Constants';
import { setMenuSelectedItem } from './Services/MenuSelectedItem';
import SetBreadcrumbsCorrectPath from './Components/FolderTable/HeaderWrapper/BreadcrumbsWrapper/SetBreadcrumbsCorrectPath';
import { getSearchStatus, resetSearch } from './Services/Search.service';
import { TPAComponentsProvider } from 'wix-ui-tpa';

const Widget: FC<WidgetProps<ControllerProps>> = (props: ControllerProps) => {
  const {
    isMobile,
    user,
    baseUrl,
    instance,
    requestLogin,
    navigateToPricingPlansApp,
    appParams,
    compId,
    isEditor_X,
    biToken,
    isEditor,
    appUrls,
    isMembersArea,
    getViewedMemberId,
    viewedUserId,
    isPreview,
    newSettings,
    navigateToLibrary,
    settingsCommandParams,
  } = props;
  const { t }: { t: TFunction } = useTranslation();
  const [, breadCrumbs]: any = useObservable(getBreadCrumbs());
  const [, settingsData]: any = useObservable(getSettingsData());
  const [, searchStatus = false]: any = useObservable(getSearchStatus());
  const [currentUserId] = useState(viewedUserId);
  useMemo(() => {
    if (newSettings?.rootFolderId) {
      setSettingsData(newSettings);
    }
  }, [newSettings]);
  useMemo(() => {
    const refreshRootFolder = (settingsCommandParams as any)?.refreshRootFolder;
    if (refreshRootFolder) {
      setSelectedFolderId(refreshRootFolder);
      setSelectedFolder(refreshRootFolder);
    }
  }, [settingsCommandParams]);
  const rootFolderId = settingsData?.rootFolderId;

  const [state, setState] = useState({
    isLoadingBiEventSent: false,
  });
  const { accessibilityEnabled, dimensions, formFactor, viewMode, scrollTo } =
    props.host;

  const rtlStatus = settingsData?.settings?.displaySettings?.textAlignment;
  useObservable(searchObservable$);
  const bi = useBi();
  const navigateDeepLink = useCallback(async () => {
    if (!settingsData.rootFolderId || typeof window === 'undefined') {
      return '';
    }
    if (isEditor || isMembersArea) {
      return rootFolderId;
    }
    const fullUrl = new URL(window.location.href);
    const matchedFolders = fullUrl.pathname.match(
      /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
    );
    let folderId = matchedFolders?.[0];
    if (folderId) {
      if (folderId === settingsData.rootFolderId) {
        return folderId;
      }

      let libraryData = await getLibraryDataById(folderId);

      if (!libraryData.libraryItems?.length) {
        setSelectedFolder(folderId, undefined, settingsData.rootFolderId);
        return folderId;
      }
      let folder = libraryData?.libraryItems?.[0];
      if (folder?.fileFields && folder?.parentFolderId) {
        libraryData = await getLibraryDataById(folder.parentFolderId);
        if (!libraryData.libraryItems?.length) {
          return settingsData.rootFolderId;
        }
        folder = libraryData.libraryItems?.[0];
        folderId = folder.id || '';
      }
      const permissions = await authorizeActions(
        { libraryItems: [{ id: folder?.id }] },
        folder?.parentFolderId,
      );
      if (folder) {
        (folder as any).authorizeActions =
          permissions?.libraryItems?.[0]?.authorizeActions;
        const folderPermissions =
          folder &&
          permissions?.libraryItems?.[0]?.authorizeActions?.find(
            (itemData: any) => itemData.action === 'VIEW',
          );

        if (folderPermissions?.status === Status.FORBIDDEN) {
          await setMenuSelectedItem(folder);
          setOpenedModal(MODAL_TYPES.NO_PERMISSION);
        } else {
          const permissionsMatch =
            fullUrl.pathname.indexOf(`/permissions/${matchedFolders?.[1]}`) >
            -1;
          if (
            folder.parentFolderId === settingsData.rootFolderId &&
            matchedFolders?.length === 2 &&
            permissionsMatch
          ) {
            await setMenuSelectedItem(folder);
            setOpenedModal(MODAL_TYPES.MANAGE_PERMISSIONS, {
              userId: matchedFolders?.[1],
            });
            return rootFolderId;
          } else {
            await setSelectedFolderId(folderId);
            await setSelectedFolder(folderId);

            const newPath = folder?.path;
            if (newPath) {
              newPath.push({
                libraryItemId: folder?.id,
                name: folder?.name,
              });

              const mapped = newPath?.map((item, index) => ({
                id: item.libraryItemId,
                value: index ? item.name : t('root.folder.label'),
              }));
              setBreadCrumbs(mapped);
            }
            return folderId;
          }
        }
      }
      return settingsData.rootFolderId;
    } else {
      return settingsData.rootFolderId;
    }
  }, [isEditor, isMembersArea, rootFolderId, settingsData?.rootFolderId, t]);

  const memoizedValue = useMemo(() => {
    return {
      accessibilityEnabled,
      dimensions,
      formFactor,
      viewMode,
      isMobile,
      instance,
      t,
      baseUrl,
      isEditor_X,
      isEditor,
      isPreview,
      scrollTo,
      appUrls,
      isMembersArea,
      getViewedMemberId,
      viewedUserId,
    };
  }, [
    isEditor_X,
    accessibilityEnabled,
    dimensions,
    formFactor,
    instance,
    isMobile,
    t,
    viewMode,
    baseUrl,
    isEditor,
    isPreview,
    scrollTo,
    appUrls,
    isMembersArea,
    getViewedMemberId,
    viewedUserId,
  ]);

  const initEmptyData = async () => {
    await setRootFolder('', '');
    await setSelectedFolderId('');
    await setSelectedFolder(undefined);
  };

  const initData = useCallback(async () => {
    await setRootFolder(settingsData.rootFolderId, t('root.folder.label'));

    const myFolderId = await navigateDeepLink();
    if (myFolderId === settingsData.rootFolderId) {
      setSelectedFolderId(settingsData.rootFolderId);
    }
  }, [navigateDeepLink, settingsData?.rootFolderId, t]);

  useEffect(() => {
    const { isLoggedIn = false } = user;
    if (viewedUserId !== currentUserId && isLoggedIn) {
      initEmptyData();
      initData();
    }
  }, [currentUserId, initData, user, user.isLoggedIn, viewedUserId]);
  useEffect(() => {
    const { appDefinitionId, instanceId } = appParams;
    setBiEventsParams({
      visitor_id: user?.id,
      app_id: appDefinitionId,
      instance_id: instanceId,
      logger: bi,
      comp_id: compId,
      owner: user?.role,
      biToken,
    });

    if (instance) {
      setAppSettings(memoizedValue);
      setUser(user);
      setRequestLogin(requestLogin);
      setNavigateToPricingPlans(navigateToPricingPlansApp);
      setNavigateToLibrary(navigateToLibrary);
      setSettingsDataAPI();
    }
  }, [
    appParams,
    bi,
    biToken,
    compId,
    instance,
    memoizedValue,
    navigateToPricingPlansApp,
    requestLogin,
    user,
  ]);
  useEffect(() => {
    if (settingsData?.rootFolderId) {
      initData();
    } else {
      initEmptyData();
    }
  }, [initData, navigateDeepLink, settingsData?.rootFolderId, t]);
  useEffect(() => {
    const handlePopstate = async (event: PopStateEvent) => {
      if (searchStatus) {
        return resetSearch();
      }
      if (event.state?.itemId) {
        const itemId = event.state.itemId;
        const breadCrumbs1 = event.state?.breadCrumbs;

        setSelectedFolderId(itemId);
        setSelectedFolder(itemId);
        SetBreadcrumbsCorrectPath({ id: itemId }, breadCrumbs);
        setBreadCrumbs(breadCrumbs1);
      } else {
        setSelectedFolderId(settingsData.rootFolderId);
        setSelectedFolder(settingsData.rootFolderId);
        setBreadCrumbs([
          { id: settingsData.rootFolderId, value: t('root.folder.label') },
        ]);
      }
    };
    if (window) {
      window.addEventListener('popstate', handlePopstate);
    }
    return () => {
      if (window) {
        window.removeEventListener('popstate', handlePopstate);
      }
    };
  }, [breadCrumbs, t, settingsData?.rootFolderId, searchStatus]);
  useEffect(() => {
    if (!state?.isLoadingBiEventSent && breadCrumbs !== undefined) {
      if (rootFolderId) {
        biService(
          {
            isEditor: viewMode === EDITORVIEW,
          },
          BI_FILE_SHARE_WIDGET_APP_LOADED,
        );
        setState((prevState) => ({ ...prevState, isLoadingBiEventSent: true }));
      }
    }
  }, [breadCrumbs, rootFolderId, state?.isLoadingBiEventSent, viewMode]);
  return (
    <TPAComponentsProvider
      value={{ rtl: rtlStatus === TextAlignment.RIGHT, mobile: isMobile }}
    >
      <div
        tabIndex={0}
        aria-label={t('app.aria.label')}
        className={st(classes.root, {
          isMembersArea,
          isMobile,
          isClassicEditor: !isEditor_X,
        })}
        dir={
          rtlStatus === TextAlignment.RIGHT
            ? AppAlignment.RTL
            : AppAlignment.LTR
        }
      >
        <div className={st(classes.widgetContainer, {})}>
          <FolderTable />
        </div>
      </div>
    </TPAComponentsProvider>
  );
};

export default Widget;
