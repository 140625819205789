import React, { useEffect, useState } from 'react';
import { st, classes } from './ShareWrapper.st.css';
import { Text, SocialBar, CopyUrlButton } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import Facebook from 'wix-ui-icons-common/on-stage/Facebook';
import {XCorp} from '@wix/wix-ui-icons-common/on-stage';
import { shareItem } from '../../../Services/ShareModalService';

const ShareWrapper = ({ currentItem, isMobile }: any) => {
  const { t }: { t: any } = useTranslation();
  const title = t('app.widget.share.dialog.title');
  const [shareUrl, setShareUrl] = useState('');
  useEffect(() => {
    (async () => {
      const url: any = await shareItem(currentItem?.id);
      setShareUrl(url);
    })();
  }, [currentItem?.id]);
  return (
    <div className={classes.ShareWrapperContainer}>
      <div className={classes.titleContainer}>
        <Text className={st(classes.modalTitle, { isMobile })}>{title}</Text>
      </div>
      <div className={classes.socialBarContainer}>
        <SocialBar>
          <SocialBar.Icon
            tooltip={t('app.widget.share.dialog.facebook.toolTip')}
            icon={<Facebook className={classes.socialIcon} />}
            href={`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`}
          />
          <SocialBar.Icon
            tooltip={t('app.widget.share.dialog.twitter.toolTip')}
            icon={<XCorp className={classes.socialIcon} />}
            href={`https://twitter.com/intent/tweet?url=${shareUrl}`}
          />
          <CopyUrlButton
            className={classes.copyUrlButtonContainer}
            tooltipText={t('app.widget.share.dialog.CopyLink.toolTip')}
            successText={t('app.widget.share.dialog.CopyLink.successText')}
            url={shareUrl}
          />
        </SocialBar>
      </div>
    </div>
  );
};

export default ShareWrapper;
