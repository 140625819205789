import {
  getOpenedModal$,
  setOpenedModal$,
} from '../Observables/Modals.observable';

export const setOpenedModal = (openedModal: string, params?: object) => {
  setOpenedModal$(openedModal, params);
};

export const getOpenedModal = () => {
  return getOpenedModal$();
};
