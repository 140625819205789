import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { DisplaySettings } from '@wix/ambassador-file-sharing-v1-settings/types';
import useObservable from '../../../Shared/Hooks/useObservable.Hook';
import {
  getSelectedFolderId,
  setSelectedFolder,
} from '../../Services/SelectedFolder.service';
import MemberesHeaderWrapper from './HeaderWrapper/MembersHeaderWrapper';
import HeaderWrapper from './HeaderWrapper/HeaderWrapper';
import TableWrapper from './TableWrapper/TableWrapper';
import DialogWrapper from '../../Shared/DialogWrapper/DialogWrapper';
import { getOpenedModal } from '../../Services/Modals.service';
import ToastWrapper from '../../Shared/ToastWrapper/ToastWrapper';
import { getToast } from '../../Services/Toast.service';
import { setTableSettings } from '../../Services/TableSettings.service';
import { getSort } from '../../Services/Sort.service';
import { classes } from './FolderTable.st.css';
import {
  getAppSettings,
  getSettingsData,
} from '../../Services/AppSettings.service';
import MobileHeaderWrapper from './MobileView/MobileHeaderWrapper/MobileHeaderWrapper';
import { useWixSdk } from '@wix/yoshi-flow-editor';
import { widgetDetailsBi } from '../../BiEvents/SharedBiEventsCallBack/SharedBiEventsCallBack';
import { getRootFolder } from '../../Services/RootFolder.service';
import {
  CONTRIBUTORS_KEY,
  FAVORITES_COUNTER_KEY,
  FILE_SIZE_NUMBER_OF_ITEMS_KEY,
  LAST_UPDATED_KEY,
  VIEWS_COUNTER_KEY,
} from '../../../Constants/Display.Constants';

const debounceGetSelectedFolder = debounce(async (selectedFolderId) => {
  await setSelectedFolder(selectedFolderId);
});
const FolderTable = () => {
  const [, selectedFolderId] = useObservable(getSelectedFolderId());
  const [, sortObj]: any = useObservable(getSort());
  const [, openedModal] = useObservable(getOpenedModal());
  const [, Toast]: any = useObservable(getToast());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const [, settingsData]: any = useObservable(getSettingsData());
  const { Wix } = useWixSdk();
  const [firstLoad, setFirstLoad] = useState(true);
  const alignmentSettings =
    settingsData?.settings?.displaySettings?.textAlignment;
  const fileIconStyleSettings =
    settingsData?.settings?.designSettings?.fileIcon;
  const isShowFavorites = useMemo(
    () => settingsData?.settings?.favoriteSettings?.isMembersAllowedToFavorite,
    [settingsData?.settings],
  );
  const folderIdSettings = settingsData?.rootFolderId;
  const isMobile = getAppSettings !== undefined ? appSettings?.isMobile : '';
  const isMembersArea = appSettings?.isMembersArea ?? false;

  const _setTableSettings = useCallback(() => {
    const results: any = [{ id: 'SEARCH_BAR', value: true }];
    const { displaySettings }: { displaySettings: DisplaySettings } =
      settingsData.settings;
    results.push({
      id: 'FILE_SIZE_NUMBER_OF_ITEMS',
      value: displaySettings[FILE_SIZE_NUMBER_OF_ITEMS_KEY],
    });

    results.push({
      id: 'LAST_UPDATED',
      value: displaySettings[LAST_UPDATED_KEY],
    });

    results.push({
      id: 'VIEWS_COUNTER',
      value: displaySettings[VIEWS_COUNTER_KEY],
    });
    results.push({
      id: 'FAVORITES',
      value: isShowFavorites,
    });
    results.push({
      id: 'FAVORITES_COUNTER',
      value: displaySettings[FAVORITES_COUNTER_KEY],
    });
    results.push({
      id: 'CONTRIBUTORS',
      value: displaySettings[CONTRIBUTORS_KEY],
    });
    setTableSettings(results);
  }, [settingsData?.settings, isShowFavorites]);
  useEffect(() => {
    if (settingsData && settingsData.settings) {
      _setTableSettings();
    }
  }, [_setTableSettings, settingsData]);

  useEffect(() => {
    if (sortObj?.sortBy === null) {
      return;
    }

    if (settingsData?.settings?.displaySettings.defaultSort) {
      if (appSettings?.isEditor && !appSettings?.isPreview) {
        if (!firstLoad) {
          if (
            sortObj?.sortBy ===
              settingsData?.settings?.displaySettings.defaultSort &&
            sortObj?.orientation ===
              settingsData?.settings?.displaySettings.defaultOrientation
          ) {
            return;
          }
        }
      }
      if (selectedFolderId && selectedFolderId !== '') {
        debounceGetSelectedFolder(selectedFolderId);
        setFirstLoad(false);
      }
    }
  }, [
    appSettings?.isEditor,
    firstLoad,
    selectedFolderId,
    settingsData?.settings?.displaySettings.defaultSort,
    settingsData?.settings?.displaySettings.defaultOrientation,
    sortObj?.sortBy,
    sortObj?.orientation,
    appSettings?.isPreview,
  ]);

  useEffect(() => {
    let id: any = '';
    if (Wix && appSettings) {
      id = Wix?.addEventListener(Wix?.Events.SITE_PUBLISHED, (res) => {
        widgetDetailsBi(
          settingsData.settings.displaySettings,
          fileIconStyleSettings,
          folderIdSettings,
          rootFolder.value,
        );
      });
    }
    return () => {
      if (Wix && id !== '' && 'removeEventListener' in Wix) {
        (Wix as any)?.removeEventListener(Wix?.Events.SITE_PUBLISHED, id);
      }
    };
  }, [
    Wix,
    alignmentSettings,
    appSettings,
    appSettings?.isEditor,
    fileIconStyleSettings,
    folderIdSettings,
    rootFolder,
    settingsData?.rootFolderId,
    settingsData?.settings?.displaySettings,
  ]);

  return (
    <div>
      {Toast && Toast?.skin.length > 0 && Toast?.content ? (
        <div
          className={
            (!isMobile ? classes.toastContainer : '') +
            ' ' +
            classes.toastContainerZindex
          }
        >
          <ToastWrapper />
        </div>
      ) : null}
      <>
        {appSettings !== undefined && appSettings.isMobile === true ? (
          <>
            {isMembersArea ? (
              <MemberesHeaderWrapper />
            ) : (
              <MobileHeaderWrapper />
            )}
            <TableWrapper />
          </>
        ) : (
          <div
            className={classes.desktopContainer + ' ' + classes.appContainer}
          >
            {isMembersArea ? <MemberesHeaderWrapper /> : <HeaderWrapper />}
            <TableWrapper />
          </div>
        )}
        {openedModal && (openedModal as any)?.type !== '' && (
          <DialogWrapper
            type={(openedModal as any)?.type}
            isMobile={appSettings?.isMobile}
          />
        )}
      </>
    </div>
  );
};

export default FolderTable;
