import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Text, Divider, Tabs, TabsSkin, TabsAlignment } from 'wix-ui-tpa';
import { st, classes } from './HeaderWrapper.st.css';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getRootFolder } from '../../../Services/RootFolder.service';
import { getTableSettings } from '../../../Services/TableSettings.service';
import { TableColumns } from '../../../../Constants/TableHeader.Constants';
import { getUser } from '../../../Services/User.service';
import {
  getAppSettings,
  getSettingsData,
} from '../../../Services/AppSettings.service';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  st as dividerStyle,
  classes as dividerClass,
} from '../../../Shared/SharedDivider.st.css';
import {
  getCurrentTab,
  setCurrentTab,
} from '../../../Services/MemberTab.service';
import { MemberTab } from '../../../Observables/MemberTab.observable';
import {
  getSelectedFolder,
  setSelectedFolder,
} from '../../../Services/SelectedFolder.service';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../../../settingsParams';
import SortWrapper from '../TableWrapper/TableHeaderWrapper/TableHeader/Sort/SortWrapper';
import { TextAlignment } from '@wix/ambassador-file-sharing-v1-settings/types';

const MembersHeaderWrapper = () => {
  const { t } = useTranslation();
  const settings = useSettings();
  const [, currentTab = MemberTab.Uploads] = useObservable(getCurrentTab());
  const [, rootFolder]: any = useObservable(getRootFolder());
  const [, selectedFolder]: any = useObservable(getSelectedFolder());
  const [, tableDisplaySettings]: any = useObservable(getTableSettings());
  const [, user]: any = useObservable(getUser());
  const [, appSettings]: any = useObservable(getAppSettings());
  const [, settingsData]: any = useObservable(getSettingsData());
  const isMobile = appSettings?.isMobile ?? false;
  const [, setDisplaySettings] = useState({
    search: false,
  });
  const membersTabTile = settings.get(settingsParams.membersTabTitle);
  const membersTabSubtitle = settings.get(settingsParams.membersTabSubtitle);
  const rtlStatus = settingsData?.settings?.displaySettings?.textAlignment;
  const handleDisplaySettings = useCallback(() => {
    const showSearch = tableDisplaySettings.find(
      (data: any) => data.id === TableColumns.SEARCH_BAR,
    );
    setDisplaySettings({
      search: showSearch && showSearch.value,
    });
  }, [tableDisplaySettings]);

  useEffect(() => {
    if (tableDisplaySettings && tableDisplaySettings.length > 0) {
      handleDisplaySettings();
    }
  }, [handleDisplaySettings, tableDisplaySettings]);

  const isEditorX: boolean = appSettings?.isEditor_X ?? false;
  const viewedUserId = appSettings?.viewedUserId;
  const sameUser = useMemo(
    () => user?.id === viewedUserId,
    [user, viewedUserId],
  );
  return (
    <div tabIndex={0} className={st(classes.membersPageTop, { isMobile })}>
      <div
        className={st(classes.headerWrapperContainer, {
          isEditorX,
          isMembersArea: true,
          isMobile,
        })}
      >
        <Text className={st(classes.membersPageTitle)}>{membersTabTile}</Text>
        {membersTabSubtitle && (
          <Text className={st(classes.membersPageSubtitle)}>
            {membersTabSubtitle}
          </Text>
        )}
      </div>
      {sameUser && (
        <div className={st(classes.tabWrapper, { isMobile })}>
          <Tabs
            items={[
              { title: t('widget.members.my_uploads.title'), id: 'tabMembers' },
              {
                title: t('widget.members.my_favorites.title'),
                id: 'tabFavorites',
              },
            ]}
            activeTabIndex={currentTab}
            onTabClick={(tabIndex) => {
              setCurrentTab(tabIndex);
              setSelectedFolder(rootFolder.id);
            }}
            skin={TabsSkin.fullUnderline}
            alignment={
              rtlStatus === TextAlignment.RIGHT
                ? TabsAlignment.right
                : TabsAlignment.left
            }
            className={st(classes.tabContainer)}
          />
        </div>
      )}
      {!sameUser && (
        <Divider className={dividerStyle(dividerClass.dividerStyle)} />
      )}
      {isMobile && selectedFolder?.libraryItems?.length > 0 && (
        <div className={st(classes.sortWrapper)}>
          <SortWrapper />
          <Divider className={dividerStyle(dividerClass.dividerStyle)} />
        </div>
      )}
    </div>
  );
};

export default MembersHeaderWrapper;
