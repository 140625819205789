import React, { useEffect, useState } from 'react';
import { Popover, Text } from 'wix-ui-tpa';
import { st, classes } from './MoveContent.st.css';
// import { onRowClick } from '../../../Services/Move/Move.service';
import Folder from 'wix-ui-icons-common/on-stage/Folder';
import ChevronRight from 'wix-ui-icons-common/on-stage/ChevronRight';
import { MoveEmptyState } from '../MoveEmptyState/MoveEmptyState';
import useObservable from '../../../../Shared/Hooks/useObservable.Hook';
import { getSelectedItems } from '../../../Services/SelectedItems.service';
import { getMenuSelectedItem } from '../../../Services/MenuSelectedItem';
import { classes as sharedClasses } from '../../SharedText.st.css';
import { AllowedAction } from '@wix/ambassador-file-sharing-v1-library-item/types';
import { useTranslation } from '@wix/yoshi-flow-editor';

export const MoveContent = (props: any) => {
  const { currentSubFolders, handleIsRowClicked, isMobile, rootFolderId } =
    props;
  const [error1, selectedItemsIds]: any = useObservable(getSelectedItems());
  const [error2, currentItem]: any = useObservable(getMenuSelectedItem());
  const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
  const { t } = useTranslation();
  return (
    <div>
      {currentSubFolders?.length !== 0 ? (
        currentSubFolders?.map((data: any) => {
          const isSourceFolder = currentItem?.id === data?.folderId;
          const allowedActions = data?.permissions?.allowedActions;
          const canUpload =
            allowedActions &&
            allowedActions.indexOf(AllowedAction.UPLOAD_FILE) >= 0;
          const isRoot = rootFolderId === data?.folderId;
          const disabledRowClassName =
            !isRoot && (isSourceFolder || !canUpload)
              ? classes.disabledRow
              : selectedItemsIds !== undefined && selectedItemsIds.length > 0
              ? selectedItemsIds?.includes(data.folderId)
                ? classes.disabledRow
                : ''
              : '';

          return (
            <div
              id={data.folderId}
              onClick={() => {
                if (!disabledRowClassName) {
                  handleIsRowClicked(true, data.folderId, data.name);
                }
              }}
              onMouseEnter={() => {
                if (disabledRowClassName) {
                  setHoveredRowId(data.folderId);
                }
              }}
              onMouseLeave={() => setHoveredRowId(null)}
            >
              <Popover
                flip={false}
                showArrow={false}
                placement="top"
                shown={
                  hoveredRowId === data.folderId && disabledRowClassName !== ''
                }
                style={{ display: 'block' }}
                contentClassName={classes.popOverContent}
              >
                <Popover.Element>
                  <div className={`${classes.row} ${disabledRowClassName}`}>
                    <span className={classes.iconStyle}>
                      <Folder />
                    </span>
                    <div
                      className={`${classes.folder_Name} ${
                        sharedClasses.contentEllipsis
                      } ${isMobile ? classes.foldr_name_mobile : ''}`}
                    >
                      {data.name}
                    </div>
                    <span className={classes.iconStyle}>
                      <ChevronRight />
                    </span>
                  </div>
                </Popover.Element>
                <Popover.Content>
                  <Text>{t('app.widget.cant_be_moved_here.notification')}</Text>
                </Popover.Content>
              </Popover>
            </div>
          );
        })
      ) : (
        <MoveEmptyState />
      )}
    </div>
  );
};
